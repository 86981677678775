/* Navbar container styles */
.navbar-saphrone{
    width: 500;
    height: 100%;
    position: fixed;
    top: 0;
    left: -300px; /* Initially hidden off-screen */
    transition: left 0.3s ease-in-out;
    background-color: rgb(25, 25, 86);
    color: white;
    z-index: 20;
  }
  
  /* Navbar open state styles */
  .navbar-saphrone.open {
    left: 0;
  }
  
  /* Toggle button styles */
  .toggle-button {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 2; /* Ensure it's above the navbar */
  }
  
  /* Navigation links styles (customize as needed) */
  nav .saphrone-nav {
    list-style-type: none;
    padding: 0;
  }
  
  nav .saphrone-nav li {
    margin: 10px;
  }
  
  nav .saphrone-nav li a {
    text-decoration: none;
    color: white;
    font-size: 18px;

  }
  
.print-content {
    display: none;
  }
  
  @media print {
    .visible {
      display: block;
    }
  
    .hidden {
      display: none;
    }
  }
  
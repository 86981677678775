 .form-select, .form-floating{
    width: 300px;
    margin-right: 5px;
    display: inline-block;
    margin-top: 1px;
}

#fil{
    width: 220px;
    display: inline-block;
    margin: 10px;
    margin-top: 5px;
}
